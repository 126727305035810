<template>
  <div class="data-content flex-box vertical">
    <a-card :bordered="false">
      <div slot="title" class="flex-box align-center">
        <span>预警分析</span>
        <div class="select-box flex-box">
          <div
            class="option-item"
            :class="{ active: option.value == analysisType }"
            v-for="option in ymOptions"
            :key="option.value"
            @click="analysisSelect(option)"
          >
            {{ option.label }}
          </div>
        </div>
      </div>
      <div class="flex-box">
        <chart :option="levelOption" class="chart-box flex-grow" />
        <chart :option="countOption" class="chart-box flex-grow" />
      </div>
    </a-card>
    <div class="meteorology-content data-piece content flex-grow">
      <div class="data-title">
        <div class="rec-title-text">共{{ pagination.total }}条记录</div>
        <a-form-model class="query-form" layout="inline">
          <a-form-model-item>
            <a-range-picker
              v-model="form.alarm_time"
              :show-time="{ format: 'HH:mm' }"
              :placeholder="['预警开始时间', '预警结束时间']"
              format="YYYY-MM-DD HH:mm"
              value-format="YYYY-MM-DD HH:mm"
              @change="updateList"
              class="range-picker"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              v-model="form.alarm_level"
              :options="levelOptions"
              placeholder="选择预警级别"
              allowClear
              style="width: 200px"
              @change="updateList"
            ></a-select>
          </a-form-model-item>
        </a-form-model>
        <a-button @click="updateList">查询</a-button>
      </div>
      <div class="data-area">
        <a-table
          ref="dataTable"
          :columns="columns"
          row-key="id"
          :data-source="list"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ y: h }"
          @change="handleTableChange"
        >
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getKeyTitle, isEmpty } from "../../common/js/tool";
import {
  ymOptions,
  getLevalPieChartOption,
  getCountLineChartOption,
  levelOptions,
} from "../../common/constant/safe";

export default {
  name: "MeteorologyData",
  data() {
    return {
      type: 8, // 气象
      ymOptions,
      analysisType: 1, // 默认月
      h: 500,
      list: [],
      form: {},
      loading: false,
      levelList: [],
      columns: [
        { title: "预警时间", dataIndex: "create_time" },
        { title: "预警内容", dataIndex: "type" },
        {
          title: "预警级别",
          dataIndex: "level",
          customRender: (text) => (
            <span>{getKeyTitle(levelOptions, text, "value", "label")}</span>
          ),
        },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      searchKeyType: {
        create_time: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&filter[${key}][gte]=${value[0]}&filter[${key}][lte]=${value[1]}`;
          }
          console.log(res);

          return res;
        },
      },
      levelOptions,
      levelOption: {},
      countOption: {},
    };
  },
  watch: {
    analysisType() {
      this.getLevelChart();
    },
  },
  created() {
    this.getData();
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    // 获取设备数据
    getData() {
      this.$axios({
        url: "/admin/security-alarm/qxyj-count",
        method: "GET",
      }).then((res) => {
        this.monthLevelObj = res.month || {};
        this.yearLevelObj = res.year || {};
        this.analysisObj = res.day || {};
        this.getAnalysisChart();
        this.getLevelChart();
      });
    },
    // 预警等级
    getLevelChart() {
      let levelObj = {};
      if (this.analysisType == 1) {
        levelObj = this.monthLevelObj;
      } else {
        levelObj = this.yearLevelObj;
      }
      let data = [
        {
          name: "一级预警",
          value: levelObj[0] || 0,
        },
        {
          name: "二级预警",
          value: levelObj[1] || 0,
        },
        {
          name: "三级预警",
          value: levelObj[2] || 0,
        },
        {
          name: "四级预警",
          value: levelObj[3] || 0,
        },
      ];
      this.levelOption = getLevalPieChartOption(data);
    },
    getDate(date) {
      let dateArr = date?.split("-");
      dateArr.shift();
      date = dateArr.join("/");
      return date;
    },
    // 预警次数分析
    getAnalysisChart() {
      let analysisData = Object.entries(this.analysisObj);
      const data = analysisData?.map((item) => {
        let key = item[0];
        let value = item[1];
        let date = this.getDate(key);
        return {
          date,
          count: value,
        };
      });
      this.countOption = getCountLineChartOption(data);
    },
    analysisSelect(option) {
      this.analysisType = option.value;
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      let url = `/admin/security-alarm?sort=-id&filter[device_type]=${this.type}`;
      let { current, pageSize } = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      url += this.getQuery();
      this.$axios({ url, noTempleFilter: false })
        .then((res) => {
          this.loading = false;
          let list = res.data;
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
    getQuery(ex) {
      let query = "";
      let form = this.form;
      let searchKeyType = this.searchKeyType;
      for (let key in form) {
        if (!isEmpty(form[key])) {
          //模糊搜索
          let type = searchKeyType[key] || 0;
          if (typeof type == "function") {
            query += type(form, key);
          } else if (type == 1) {
            query += `&filter[${key}][like]=${form[key]}`;
          } else if (type == 2) {
            query += `&${key}=${form[key]}`;
          } else {
            if (ex) {
              query += `&${key}=${form[key]}`;
            } else {
              query += `&filter[${key}]=${form[key]}`;
            }
          }
        }
      }
      return ex ? query.replace("&", "?") : query;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../common/less/safe.less");

.data-area {
  margin-bottom: 0;
}

.meteorology-content {
  display: flex;
  flex-direction: column;
  margin: 16px auto 0;
}
</style>
